@import "variables.scss";
@import "default.scss";
@import "auth.scss";


body {
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
}

h2, h1 {
    margin: 0;
    padding: 0;
    font-size: inherit;
}

h1 {
    font-size: inherit;

}

a {
    color: inherit;
    text-decoration: none;
}

p {
    margin: 0;
}


#root {
    width: 100%;
    min-height: 100vh;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto 1fr auto;
    padding: 0;
    column-gap: $lh;
    padding: $lh; 
    background: rgb(34, 34, 34);
    color: white
}
.chapter {
    margin-bottom: $lh;
}
.header {
    grid-column: 1 / span 4;
    padding-bottom: $lh / 2;
}

.top {
    min-height: 10em;
}

.grid-item {
    grid-column: span 1;
    display: block;

    @media only screen and (max-width: 900px) {
        grid-column: span 2;
     }

    @media only screen and (max-width: 750px) {
        grid-column: span 4;
     }

}

.project-link {
    img {
        width: 100%;
        border-bottom-right-radius: $lh / 4 ;
        border-bottom-left-radius: $lh / 4 ;
    }
}

.project-link:hover {
    opacity: 0.5;
    // transition: 0.5s;
}

.footer {
    grid-column: 1 / span 4;
    // margin-top: $lh * 4;
    opacity: 0.2;

    @media only screen and (max-width: 750px) {
        margin-top: $lh;
     }
}

.bar {
    width: 100%;
    height: 1.8vw;
    background: rgb(124, 124, 124);
    border-top-left-radius: $lh / 4 ;
    border-top-right-radius: $lh / 4 ;


    @media only screen and (max-width: 900px) {
        height: $lh / 1.5;
     }
}

.dot {
    font-size: 2vw;
    line-height: 2vw;
    color: rgb(37, 37, 37);
    animation: pulse 1.5s infinite;
    margin-left: 0.3vw;
}

.live {
    color: rgb(124, 124, 124);  
}

.about-link {
    margin-bottom: $lh / 4;
    display: inline-block;
    opacity: 0.2;
}

.introduction {
    margin-top: $lh;
    margin-bottom: $lh;

    background: linear-gradient(90deg,var(--color) 24%,var(--color2) 43%,var(--color2) 63%,var(--color2) 80%,var(--color));
    animation: highlight 10s linear infinite;
    background-size: 200% 100%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    outline-color: var(--color);
}


@keyframes highlight{0%{background-position:180%}to{background-position:-20%}}

:root {
    --color: rgb(132, 0, 255);
    --color2: rgb(34, 34, 34);
    --colorb: rgb(34, 34, 34);
  }


.popup {
    // background: black;
    position: fixed;
    top: $lh;
    left: $lh;
    border-radius: $lh;
    width: calc(100vw - 4em);
    height: calc(100vh - 4em);
    backdrop-filter: blur(0.8em);
    padding: $lh;
    opacity: 1;
    transition: 1s all;
}

.js--hidden {
    display: none;
    opacity: 0;
}

iframe {
    margin-top: $lh;
    width: 80vw;
    height:  45vw;;
    position: relative;
    left: 50%;
    transform: translate(-50%,0);
}


.close-button {
    color: red;
    margin-bottom: $lh / 2;
}